import { styled } from '@mui/material/styles';

export const ModalContenWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  top: '50%',
  left: '50%',
  maxWidth: '392px',
  width: '100%',
  boxShadow: '24',
  borderRadius: '6px',
  backgroundColor: '#FFFFFF',
  outline: 'none',
});

export const ConnetionBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  justifyContent: 'start',
  padding: '48px 28px 36px',
});

export const ConnectButtonWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '8px',
});

export const Link = styled('a')({
  textDecoration: 'underline',
  cursor: 'pointer',
  color: '#00A79D',
});
