import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { TextField } from '@mui/material';

interface INameTextField {
  value: string;
  label: string;
  validateError?: string;
  setValue: (username: string) => void;
}
const Textfield: React.FC<INameTextField> = ({ value, label, validateError, setValue }) => {
  const [errorText, setErrorText] = useState('');
  const validate = () => {
    if (typeof validateError === 'string') {
      setErrorText(validateError);
    }
  };

  useEffect(() => {
    if (typeof validateError === 'string') {
      setErrorText(validateError);
    }
  }, [validateError]);

  return (
    <TextField
      value={value}
      onChange={(e) => setValue(e.currentTarget.value)}
      fullWidth
      label={label}
      size="medium"
      margin="dense"
      inputProps={{ maxLength: 255 }}
      onBlur={validate}
      error={!!errorText.length}
      helperText={errorText}
    />
  );
};

export default observer(Textfield);
