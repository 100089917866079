import React from 'react';
import { observer } from 'mobx-react';
import store from 'store';
import { Avatar } from '@mui/material';
import avatar from 'assets/images/logo-favicon.png';

const Email: React.FC = () => {
  const { userFirstname, userLastname } = store;

  if (userFirstname || userLastname) {
    return (
      <Avatar
        alt={userFirstname + ' ' + userLastname}
        sx={{ width: 40, height: 40, backgroundColor: '#00A79D', color: '#fff', fontSize: '18px' }}
      >
        {(userFirstname && userFirstname.slice(0, 1)) + (userLastname && userLastname.slice(0, 1))}
      </Avatar>
    );
  } else {
    return (
      <Avatar
        variant="circular"
        alt=" "
        src={avatar}
        sx={{ width: 24, height: 24, backgroundColor: '#fff', padding: '8px' }}
      />
    );
  }
};

export default observer(Email);
