import React from 'react';
import { observer } from 'mobx-react';
import {
  ModalWrapper,
  ModalContenWrapper,
  ModalButtonWrapper,
  CancelButton,
  ModalTitle,
  ModalTextTop,
  ModalTextBottom,
  CloseIconButton,
  TextLink,
} from './ModalLiveDevice.styles';
import Modal from '@mui/material/Modal';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
}

const ModalLiveDevice: React.FC<Props> = ({ open = false, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalWrapper>
        <ModalContenWrapper>
          <CloseIconButton onClick={handleClose}>
            <CloseRoundedIcon />
          </CloseIconButton>
          <ModalTitle>Connect a device</ModalTitle>
          <ModalTextTop>It seems that you have no devices connected.</ModalTextTop>
          <ModalTextBottom>
            Please make sure your glowcose devices are set up and available to connect. For more details, please refer
            to Setup Guide{' '}
            <TextLink target="_blank" href="https://glowcose.com/setupv2">
              here
            </TextLink>
            .
          </ModalTextBottom>
          <ModalButtonWrapper>
            <CancelButton onClick={handleClose}>Ok</CancelButton>
          </ModalButtonWrapper>
        </ModalContenWrapper>
      </ModalWrapper>
    </Modal>
  );
};

export default observer(ModalLiveDevice);
