import React from 'react';
import { observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';

import logo from 'assets/icons/glowcose-logo.svg';
import { Wrapper, LogoWrapper, Logo, ContentWrapper } from './Login.styles';

const Login: React.FC = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <LogoWrapper>
          <Logo src={logo} alt="logo" />
        </LogoWrapper>
        <Outlet />
      </ContentWrapper>
    </Wrapper>
  );
};

export default observer(Login);
