import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  WrapperConnetions,
  ConnetionsHeader,
  ConnetionsText,
  Connetions,
  ConnetionBlock,
  ConnectionImg,
  ConnetionTitle,
  ConnetionText,
  ConnectBottomText,
  SkipButton,
  CloseIconButton,
  Logo,
  LogoWrapper,
  WrapperConnetionsNeedCGMConn,
  ConnetionsHeaderNeedCGMConn,
  ConnetionsTextNeedCGMConn,
  ConnetionsNeedCGMConn,
  ConnetionBlockNeedCGMConn,
  ConnetionImgWrapper,
} from './CGMLink.styles';
import dexcom from 'assets/images/dexcom.png';
import freestyler from 'assets/images/freestylelibre.jpg';
import store from 'store';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@mui/material';
import logo from 'assets/icons/glowcose-logo.svg';
import ModalConnectToCGM from './ModalConnectToCGM';
import ModalManualDisconnectCGM from './ModalManualDisconnectCGM';
import ModalAutolDisconnectCGM from './ModalAutolDisconnectCGM';

interface Props {
  isModal?: boolean;
  handleSkip?: () => void;
  isFirstLogin?: boolean;
}

const CGMLink: React.FC<Props> = ({ isModal, handleSkip, isFirstLogin }) => {
  const [openModalConnectDexcom, setOpenModalConnectDexcom] = useState(false);
  const [openModalManualDisconnectCGM, setOpenModalManualDisconnectCGM] = useState(false);
  const [openModalAutoDisconnectCGM, setOpenModalAutolDisconnectCGM] = useState(false);
  const [isConnectionToDexcom, setIsConnectionToDexcom] = useState(false);

  const { isDexcomConnection, isFreeLibreConnection, completeSetDescomCode, setCompleteSetDescomCode } = store;

  const dexcomConnect = () => {
    setIsConnectionToDexcom(true);
    if (isDexcomConnection) {
      setOpenModalManualDisconnectCGM(true);
    } else {
      if (isFreeLibreConnection) {
        setOpenModalAutolDisconnectCGM(true);
      } else {
        setOpenModalConnectDexcom(true);
      }
    }
  };

  const freeStyleConnect = () => {
    setIsConnectionToDexcom(false);
    if (isFreeLibreConnection) {
      setOpenModalManualDisconnectCGM(true);
    } else {
      if (isDexcomConnection) {
        setOpenModalAutolDisconnectCGM(true);
      } else {
        setOpenModalConnectDexcom(true);
      }
    }
  };

  useEffect(() => {
    if (completeSetDescomCode && isModal) {
      setCompleteSetDescomCode(false);
      if (handleSkip) {
        handleSkip();
      }
    }
    return () => setCompleteSetDescomCode(false);
  }, [completeSetDescomCode, isModal, setCompleteSetDescomCode, handleSkip]);

  return (
    <>
      {isModal ? (
        <WrapperConnetionsNeedCGMConn>
          <LogoWrapper>
            <Logo src={logo} alt="logo" />
          </LogoWrapper>
          <ConnetionsHeaderNeedCGMConn>
            {!isFirstLogin ? 'Your account has been Verified!' : 'CGM Linking'}
          </ConnetionsHeaderNeedCGMConn>
          <ConnetionsTextNeedCGMConn>
            {!isFirstLogin
              ? 'Link your CGM to provide data to your Glowcose.'
              : `Link your CGM to provide data to your Glowcose. This can be updated if needed at any time. Please keep in
            mind that changes to passwords will affect this connection. Only one CGM can be connected at a time.`}
          </ConnetionsTextNeedCGMConn>
          <ConnetionsNeedCGMConn>
            <ConnetionBlockNeedCGMConn>
              <ConnectionImg src={dexcom} />
              <ConnetionTitle>Dexcom</ConnetionTitle>
              <ConnetionText> Connect to Dexcom to link your CGM data.</ConnetionText>

              <Button color="primary" size="normal" fullWidth variant="contained" onClick={dexcomConnect}>
                Connect
              </Button>
            </ConnetionBlockNeedCGMConn>
            <ConnetionBlockNeedCGMConn>
              <ConnetionImgWrapper>
                <ConnectionImg src={freestyler} />
              </ConnetionImgWrapper>
              <ConnetionTitle>FreeStyle Libre</ConnetionTitle>
              <ConnetionText> Connect to FreeStyle Libre to link your CGM data.</ConnetionText>
              <Button color="primary" size="normal" fullWidth variant="contained" onClick={freeStyleConnect}>
                Connect
              </Button>
            </ConnetionBlockNeedCGMConn>
          </ConnetionsNeedCGMConn>
          {handleSkip && <SkipButton onClick={handleSkip}>Skip</SkipButton>}
          {handleSkip && (
            <CloseIconButton onClick={handleSkip}>
              <CloseRoundedIcon />
            </CloseIconButton>
          )}
        </WrapperConnetionsNeedCGMConn>
      ) : (
        <WrapperConnetions>
          <ConnetionsHeader>CGM Linking</ConnetionsHeader>
          <ConnetionsText>
            Link your CGM to provide data to your Glowcose. This can be updated if needed at any time. Please keep in
            mind that changes to passwords will affect this connection. Only one CGM can be connected at a time.
          </ConnetionsText>
          <Connetions>
            <ConnetionBlock>
              <ConnectionImg src={dexcom} />
              <ConnetionTitle>Dexcom</ConnetionTitle>
              <ConnetionText> Connect to Dexcom to link your CGM data.</ConnetionText>
              <Button
                color="primary"
                size="normal"
                fullWidth
                variant={isDexcomConnection ? 'outlined' : 'contained'}
                onClick={dexcomConnect}
              >
                {isDexcomConnection ? 'Disconnect' : 'Connect'}
              </Button>
            </ConnetionBlock>
            <ConnetionBlock>
              <ConnetionImgWrapper>
                <ConnectionImg src={freestyler} />
              </ConnetionImgWrapper>
              <ConnetionTitle>FreeStyle Libre</ConnetionTitle>
              <ConnetionText>Connect to FreeStyle Libre to link your CGM data.</ConnetionText>
              <Button
                color="primary"
                size="normal"
                fullWidth
                variant={isFreeLibreConnection ? 'outlined' : 'contained'}
                onClick={freeStyleConnect}
              >
                {isFreeLibreConnection ? 'Disconnect' : 'Connect'}
              </Button>
            </ConnetionBlock>
          </Connetions>
        </WrapperConnetions>
      )}
      <ModalConnectToCGM
        open={openModalConnectDexcom}
        setOpen={setOpenModalConnectDexcom}
        isConnectionToDexcom={isConnectionToDexcom}
      />
      <ModalManualDisconnectCGM
        open={openModalManualDisconnectCGM}
        setOpen={setOpenModalManualDisconnectCGM}
        isConnectionToDexcom={isConnectionToDexcom}
      />
      <ModalAutolDisconnectCGM
        open={openModalAutoDisconnectCGM}
        setOpen={setOpenModalAutolDisconnectCGM}
        onComplete={() => setOpenModalConnectDexcom(true)}
        isConnectionToDexcom={isConnectionToDexcom}
      />
    </>
  );
};

export default observer(CGMLink);
