import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { Content, Title, Caption, SaveButton } from '../Login.styles';
import { ROUTES } from '_constants';
import { Email } from 'components';
import { ButtonWrapper, ArrowBack, ArrowBackLink, TitleIcon, TitleWrapper } from './ForgotPassword.styles';
import arrow from 'assets/icons/arrow-back.svg';
import lockIcon from 'assets/images/lock-icon.png';
import store from 'store';

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const { sendResetEmail, setNeedShowNoticeCheckEmail, email, isEmailValid, needShowNoticeCheckEmail } = store;
  const gotoLogin = () => {
    navigate('/' + ROUTES.LOGIN);
  };
  useEffect(() => {
    if (needShowNoticeCheckEmail) {
      navigate('/' + ROUTES.LOGIN + '/' + ROUTES.NOTICE_CHECK_EMAIL_AFTER_FORGOT_PASS);
      setNeedShowNoticeCheckEmail(false);
    }
  }, [needShowNoticeCheckEmail, navigate, setNeedShowNoticeCheckEmail]);

  return (
    <Content>
      <TitleWrapper>
        <Title>Forgot password</Title> <TitleIcon src={lockIcon} alt=""></TitleIcon>
      </TitleWrapper>
      <Caption>Enter your email and we'll send you instructions to reset your password</Caption>
      <Email isNeedValid={true} />
      <ButtonWrapper>
        <SaveButton disabled={!(email && isEmailValid)} fullWidth variant="contained" onClick={sendResetEmail}>
          Submit
        </SaveButton>
      </ButtonWrapper>
      <ArrowBackLink onClick={gotoLogin}>
        <ArrowBack src={arrow} alt="Back to login" /> Back to login
      </ArrowBackLink>
    </Content>
  );
};

export default observer(ForgotPassword);
