import { styled } from '@mui/material/styles';
import { Button, IconButton as MuiIconButton } from '@mui/material';

export const WrapperConnetions = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '0 24px', //TODO: need test
  padding: '48px 60px 56px',
  backgroundColor: '#FFFFFF',
  borderRadius: '6px',
  position: 'relative',
});

export const WrapperConnetionsNeedCGMConn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '48px 42px 30px',
  backgroundColor: '#FFFFFF',
  borderRadius: '6px',
  position: 'relative',
  overflow: 'auto',
});

export const ConnetionsHeader = styled('div')({
  fontSize: '24px',
  lineHeight: '32px',
  color: 'rgba(58, 53, 65, 0.87)',
});

export const ConnetionsHeaderNeedCGMConn = styled('div')({
  fontSize: '24px',
  lineHeight: '32px',
  color: 'rgba(58, 53, 65, 0.87)',
  textAlign: 'center',
});

export const ConnetionsText = styled('div')({
  fontSize: '16px',
  lineHeight: '20px',
  marginTop: '8px',
  color: 'rgba(58, 53, 65, 0.68)',
});

export const ConnetionsTextNeedCGMConn = styled('div')({
  fontSize: '16px',
  lineHeight: '20px',
  marginTop: '8px',
  color: 'rgba(58, 53, 65, 0.68)',
  textAlign: 'center',
});

export const Connetions = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
});

export const ConnetionsNeedCGMConn = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '32px',
  justifyContent: 'center',
});

export const ConnetionBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '48px 40px 10px 0',
  width: '240px',
  textAlign: 'center',
  justifyContent: 'start',
});

export const ConnetionBlockNeedCGMConn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '40px 0 10px',
  width: '200px',
  textAlign: 'center',
  justifyContent: 'start',
});

export const ConnetionTitle = styled('div')({
  fontSize: '20px',
  lineHeight: '32px',
  marginTop: '24px',
});
export const ConnetionText = styled('div')({
  fontSize: '14px',
  lineHeight: '20px',
  color: 'rgba(58, 53, 65, 0.68)',
  margin: '8px 0 24px',
});
export const ConnetionImgWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '200px',
  minHeight: '200px',
  mainWidth: '200px',
  height: '200px',
  padding: '0 20px',
  margin: 'auto',
  border: '2px solid #F4F5FA',
  borderRadius: '40px',
});

export const ConnectionImg = styled('img')({
  maxWidth: '200px',
  maxHeight: '200px',
  margin: 'auto',
});

export const ConnectBottomText = styled(Button)({
  height: '42px',
  fontsize: '15px',
  lineHeight: '26px',
  color: 'rgba(58, 53, 65, 0.68)',
  marginTop: 'auto',
});

export const SkipButton = styled(Button)({
  display: 'flex',
  marginTop: '10px',
  height: '42px',
  fontsize: '13px',
  lineHeight: '22px',
  color: 'rgba(58, 53, 65, 0.68)',
});

export const CloseIconButton = styled(MuiIconButton)({
  position: 'absolute',
  top: '0',
  right: '0',
});

export const LogoWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  padding: '7px 0 32px',
});

export const Logo = styled('img')({
  width: '260px',
  maxWidth: '260px',
});
