import { styled } from '@mui/material/styles';
import { FormHelperText } from '@mui/material';

type InputProps = {
  isshow: string;
};

export const HelperText = styled(FormHelperText)<InputProps>(({ isshow = 'false' }) => ({
  opacity: isshow === 'false' ? '0' : '1',
  transition: 'all 1s',
  visibility: isshow === 'false' ? 'hidden' : 'visible',
}));
