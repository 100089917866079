export const isPasswordValid = (password: string) => {
  const validatePassword = (pass: string) => {
    const re =
      /^(?=.*[@#%\*\(\)\+\=\{\}\/\?\~\;\"\:\'\.\-\_\|\s\<\>\&\^\!\[\]])(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9@#%\*\(\)\+\=\{\}\/\?\~\;\"\:\'\.\-\_\|\s\<\>\&\^\!\[\]]{8,}$/;
    return !!pass && re.test(pass);
  };
  return validatePassword(password);
};

export const getPasswordValidateError = (password: string) => {
  let errText = '';
  if (!password) errText = 'Password cannot be empty';
  if (!isPasswordValid(password))
    errText = `Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number and one special character. 
              The password could include: @#%*()+={}/\?~;":'.-_|<>&^![] and space.`;
  return errText;
};
