import { styled } from '@mui/material/styles';

export const TitleWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});
export const ArrowBack = styled('img')({
  height: '16px',
});
export const ArrowBackLink = styled('div')({
  color: '#00A79D',
  ':hover': {
    color: '#64A7A3',
  },
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  marginTop: '20px',
  height: '24px',
});

export const Caption = styled('div')({
  textAlign: 'center',
  fontSize: '14px',
  lineHeigth: '20px',
  fontWeight: '400',
  color: 'rgba(58, 53, 65, 0.68)',
  margin: '12px 0 15px',
});

export const Email = styled('a')({
  color: '#00A79D',
  textDecoration: 'none',
});
