import React from 'react';
import { observer } from 'mobx-react';
import {
  ModalWrapper,
  ModalContenWrapper,
  ModalButtonWrapper,
  ModalTitle,
  ModalTextTop,
  ModalTextBottom,
  CloseIconButton,
} from 'views/Main/ModalLiveDevice/ModalLiveDevice.styles';
import { Modal, Button } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

interface Props {
  open: boolean;
  handleDiscard: () => void;
  handleSave: () => void;
}

const ConfirmNavigation: React.FC<Props> = ({ open = false, handleDiscard, handleSave }) => {
  return (
    <Modal open={open} onClose={handleDiscard}>
      <ModalWrapper>
        <ModalContenWrapper>
          <CloseIconButton onClick={handleDiscard}>
            <CloseRoundedIcon />
          </CloseIconButton>
          <ModalTitle>Discard your changes?</ModalTitle>
          <ModalTextTop>You have unsaved changes. Would you like to save them before leaving the page?</ModalTextTop>
          <ModalTextBottom></ModalTextBottom>
          <ModalButtonWrapper>
            <Button
              sx={{ width: 180 }}
              color="primary"
              size="normal"
              disabled={false}
              variant="outlined"
              onClick={handleDiscard}
            >
              Discard
            </Button>
            <Button
              sx={{ width: 180 }}
              color="primary"
              size="normal"
              disabled={false}
              variant="contained"
              onClick={handleSave}
            >
              Save
            </Button>
          </ModalButtonWrapper>
        </ModalContenWrapper>
      </ModalWrapper>
    </Modal>
  );
};

export default observer(ConfirmNavigation);
