import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const WrapperConnetions = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '0',
  padding: '0',
  backgroundColor: '#FFFFFF',
  borderRadius: '6px',
  position: 'relative',
});

export const TextFieldsBlock = styled('div')({
  display: 'flex',
  maxWidth: '500px',
  padding: '0 0 10px',
});

export const BottomButtonsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '24px 0',
});

export const CancelButton = styled(Button)({
  marginLeft: '16px',
});
