import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Snackbar, Button, Alert, AlertTitle } from '@mui/material';
import store from 'store';

const NeedConnectCGM = () => {
  const { errMessage, noticedMessage } = store;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!noticedMessage) setOpen(true);
  }, [errMessage, noticedMessage]);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar key={undefined} open={open} onClose={handleClose}>
      <Alert onClose={handleClose} onClick={handleClose} severity={'info'}>
        <AlertTitle>Please, connect to Dexcom </AlertTitle>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            // alert('clicked');
          }}
        >
          {'Connect'}
        </Button>
      </Alert>
    </Snackbar>
  );
};

export default observer(NeedConnectCGM);
