import React, { useState } from 'react';
import { observer } from 'mobx-react';
import store from 'store';
import { TextField } from '@mui/material';

interface IEmail {
  isNeedValid?: boolean;
  disabled?: boolean;
}

const Email: React.FC<IEmail> = ({ isNeedValid, disabled = false }) => {
  const { setEmail, email, isEmailValid } = store;
  const [emailErrorText, setEmailErrorText] = useState('');
  const validate = () => {
    if (isNeedValid) {
      if (!isEmailValid) {
        setEmailErrorText('Invalid email');
      } else {
        setEmailErrorText('');
      }
    }
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailErrorText('');
    setEmail(e.currentTarget.value);
  };
  return (
    <TextField
      disabled={disabled}
      value={email}
      onChange={onChange}
      fullWidth
      label="Email"
      size="medium"
      margin="dense"
      onBlur={validate}
      error={!!emailErrorText.length}
      helperText={emailErrorText}
      inputProps={{ maxLength: 255 }}
    />
  );
};

export default observer(Email);
