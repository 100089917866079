import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import {
  Content,
  Title,
  Caption,
  LinkWrapper,
  ForgotPassLink,
  CreateAccounText,
  CreateAccounWrapper,
  CreateAccountLink,
  Line,
  TermsText,
  TermsWrapper,
  TermsLink,
  TitleWrapper,
} from '../Login.styles';
import store from 'store';
import { ROUTES } from '_constants';
import { Email, Password } from 'components';
import { getPasswordValidateError } from 'utils';
import { Button } from '@mui/material';

const LoginForm: React.FC = () => {
  const [passwordError, setPasswordError] = useState('');
  const { signIn, clearAuth, setPassword, email, password, hasAuth, firstTimeLoggedIn } = store;
  const navigate = useNavigate();
  const gotoForgotPassword = () => {
    clearAuth();
    navigate('/' + ROUTES.LOGIN + '/' + ROUTES.FORGOT_PASSWORD);
  };
  const gotoCreateAccount = () => {
    clearAuth();
    navigate('/' + ROUTES.LOGIN + '/' + ROUTES.REGISTER);
  };
  useEffect(() => {
    if (hasAuth) {
      if (firstTimeLoggedIn) {
        navigate('/' + ROUTES.NEEDDEXCOMELOGIN);
      } else {
        navigate('/' + ROUTES.DASHBOARD);
      }
      clearAuth();
    }
  }, [hasAuth, firstTimeLoggedIn, navigate, clearAuth]);

  useEffect(() => {
    const err = getPasswordValidateError(password);
    setPasswordError(err);
  }, [password]);

  const onKeyPress = (event: React.KeyboardEvent<HTMLImageElement>) => {
    if ((event.keyCode === 13 || event.key === 'Enter') && !(!email.length || !password.length || !!passwordError)) {
      signIn();
    }
  };

  return (
    <Content>
      <TitleWrapper>
        <Title>Welcome to glowcose!</Title>
        <Caption>Please sign-in to your account</Caption>
      </TitleWrapper>
      <Email isNeedValid={true} />
      <Password
        isNeedValid={true}
        showOnlyTextInvalidPassword={true}
        value={password}
        setValue={setPassword}
        error={passwordError}
        onKeyPress={onKeyPress}
      />
      <LinkWrapper>
        <ForgotPassLink component={'button'} underline="none" onClick={gotoForgotPassword}>
          Forgot password?
        </ForgotPassLink>
      </LinkWrapper>
      <Button
        color="primary"
        size="normal"
        disabled={!email.length || !password.length || !!passwordError}
        fullWidth
        variant="contained"
        onClick={signIn}
      >
        Login
      </Button>
      <CreateAccounWrapper>
        <CreateAccounText>New on our platform?</CreateAccounText>
        <CreateAccountLink component={'button'} underline="none" onClick={gotoCreateAccount}>
          Create an account
        </CreateAccountLink>
      </CreateAccounWrapper>
      <Line></Line>
      <TermsWrapper>
        <TermsText>By using this website you agree to the&nbsp;</TermsText>
        <TermsLink underline="none" target="_blank" href="http://www.glowcose.com/terms">
          terms and conditions
        </TermsLink>
        {' and '}
        <TermsLink underline="none" target="_blank" href="http://glowcose.com/privacy">
          privacy policy
        </TermsLink>
        .
      </TermsWrapper>
    </Content>
  );
};

export default observer(LoginForm);
