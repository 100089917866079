import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import {
  RangeSetupWrapper,
  RangeLine,
  TrendingUpIcon,
  TrendingDownIcon,
  CheckIcon,
  RangeLineTitle,
  ModalContenWrapper,
  ModalButtonWrapper,
  ModalTitle,
  ModalText,
  CancelButton,
  LogoutButton,
  TextField,
  RadioGroupWrapper,
  RangeInputWrapper,
  RangeLineError,
  IconButton,
  SwitchText,
} from './SetRange.styles';
import store from 'store';
import { Modal, Radio, RadioGroup, FormControlLabel, Switch } from '@mui/material';
import { RangeTypes } from '_constants';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
}

const SetRange: React.FC<Props> = ({ open = false, setOpen }) => {
  const {
    setRange,
    getCurrentUserReadings,
    setUserSettings,
    rangeSetupUnit,
    rangeSetupHigh,
    rangeSetupInRange,
    rangeSetupLow,
    highFlash,
    lowFlash,
  } = store;
  const [rangeType, setRangeType] = useState('');
  const [highRange, setHighRange] = useState('');
  const [highRangeError, setHighRangeError] = useState('');

  const [inRange, setInRange] = useState('');
  const [inRangeError, setInRangeError] = useState('');

  const [lowRange, setLowRange] = useState('');
  const [lowRangeError, setLowRangeError] = useState('');

  const [highSetpoint, setHighSetpoint] = useState(highFlash);
  const [lowSetpoint, setLowSetpoint] = useState(lowFlash);

  useEffect(() => {
    setHighSetpoint(highFlash);
    setLowSetpoint(lowFlash);
  }, [lowFlash, highFlash]);

  useEffect(() => {
    if ((rangeSetupHigh && rangeSetupInRange && rangeSetupLow) || !open) {
      setHighRange(rangeSetupHigh + '');
      setInRange(rangeSetupInRange + '');
      setLowRange(rangeSetupLow + '');
      setRangeType(rangeSetupUnit);
    }
  }, [rangeSetupUnit, open, rangeSetupHigh, rangeSetupInRange, rangeSetupLow, setRangeType]);

  const handleClose = () => {
    setOpen(false);
    setHighRangeError('');
    setInRangeError('');
    setLowRangeError('');
  };

  const handleSaveRanges = async () => {
    await setRange(rangeType, highRange, inRange, lowRange);
    if (lowSetpoint !== lowFlash || highSetpoint !== highFlash) {
      setUserSettings(lowSetpoint, highSetpoint);
    }
    await getCurrentUserReadings();
    handleClose();
  };

  const handleChangeHighRange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    if (/^[\d]*\.?[\d]{0,2}$/.test(val)) {
      setHighRange(val);
    }
  };

  const validateHighRange = useCallback(() => {
    setHighRangeError('');
    if (parseFloat(highRange) <= parseFloat(inRange)) {
      setHighRangeError('Please consider order of values');
    }
    if (!highRange) {
      setHighRangeError(' Please enter a value');
    }
    if (rangeType === RangeTypes.MG) {
      if (parseFloat(highRange) > 400) {
        setHighRangeError('Value is too high');
      }
    } else {
      if (parseFloat(highRange) > 22.2) {
        setHighRangeError('Value is too high');
      }
    }
  }, [highRange, inRange, rangeType]);

  const validateLowRange = useCallback(() => {
    setLowRangeError('');
    if (parseFloat(lowRange) >= parseFloat(inRange)) {
      setLowRangeError('Please consider order of values');
    }
    if (!lowRange) {
      setLowRangeError(' Please enter a value');
    }
    if (rangeType === RangeTypes.MG) {
      if (parseFloat(lowRange) < 50) {
        setLowRangeError('Value is too low');
      }
    } else {
      if (parseFloat(lowRange) < 2.8) {
        setLowRangeError('Value is too low');
      }
    }
  }, [lowRange, inRange, rangeType]);

  const validateMidRange = useCallback(() => {
    setInRangeError('');
    if (!highRange) {
      setInRangeError(' Please enter a value');
    }
    validateHighRange();
    validateLowRange();
  }, [highRange, validateHighRange, validateLowRange]);

  const handleChangeMidRange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    if (/^[\d]*\.?[\d]{0,2}$/.test(val)) {
      setInRange(val);
    }
  };

  const handleChangeLowRange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    if (/^[\d]*\.?[\d]{0,2}$/.test(val)) {
      setLowRange(val);
    }
  };

  useEffect(() => {
    validateLowRange();
    validateMidRange();
    validateHighRange();
  }, [rangeType, validateLowRange, validateHighRange, validateMidRange]);

  const changeRangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHighRange('');
    setInRange('');
    setLowRange('');
    setRangeType(event.target.value as RangeTypes);
    setInRangeError('');
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContenWrapper>
        <IconButton onClick={handleClose}>
          <CloseRoundedIcon />
        </IconButton>
        <ModalTitle>Range setup</ModalTitle>
        <ModalText>Changing setpoints will update the settings on your glowcose automatically</ModalText>
        <RadioGroupWrapper>
          <RadioGroup row value={rangeType} onChange={changeRangeType}>
            <FormControlLabel value={RangeTypes.MG} control={<Radio />} label="mg/dL" />
            <FormControlLabel value={RangeTypes.MMOL} control={<Radio />} label="mmol/L" />
          </RadioGroup>
        </RadioGroupWrapper>
        <RangeSetupWrapper>
          <RangeLine>
            <RangeInputWrapper>
              <TrendingUpIcon />
              <TextField
                inputProps={{ maxLength: 10 }}
                label={'High setpoint'}
                size={'medium'}
                value={highRange}
                onChange={handleChangeHighRange}
                onBlur={validateHighRange}
              />
              <RangeLineTitle>{rangeType}</RangeLineTitle>
            </RangeInputWrapper>
            {highRangeError && highRangeError.length && <RangeLineError>{highRangeError}</RangeLineError>}
            <FormControlLabel
              control={
                <Switch checked={highSetpoint} onChange={() => setHighSetpoint(!highSetpoint)} name="highSetpoint" />
              }
              label={<SwitchText>Enable flash for high setpoint</SwitchText>}
            />
          </RangeLine>
          <RangeLine>
            <RangeInputWrapper>
              <CheckIcon />
              <TextField
                inputProps={{ maxLength: 10 }}
                label={'In range setpoint'}
                size={'medium'}
                value={inRange}
                onChange={handleChangeMidRange}
                onBlur={validateMidRange}
              />
              <RangeLineTitle>{rangeType}</RangeLineTitle>
            </RangeInputWrapper>
            {inRangeError && inRangeError.length && <RangeLineError>{inRangeError}</RangeLineError>}
          </RangeLine>
          <RangeLine>
            <RangeInputWrapper>
              <TrendingDownIcon />
              <TextField
                inputProps={{ maxLength: 10 }}
                label={'Low setpoint'}
                size={'medium'}
                value={lowRange}
                onChange={handleChangeLowRange}
                onBlur={validateLowRange}
              />
              <RangeLineTitle>{rangeType}</RangeLineTitle>
            </RangeInputWrapper>
            {lowRangeError && lowRangeError.length && <RangeLineError>{lowRangeError}</RangeLineError>}
            <FormControlLabel
              control={
                <Switch checked={lowSetpoint} onChange={() => setLowSetpoint(!lowSetpoint)} name="lowSetpoint" />
              }
              label={<SwitchText>Enable flash for low setpoint</SwitchText>}
            />
          </RangeLine>
        </RangeSetupWrapper>
        <ModalButtonWrapper>
          <LogoutButton onClick={handleClose}>Cancel</LogoutButton>
          <CancelButton
            disabled={!!(highRangeError || inRangeError || lowRangeError || !highRange || !inRange || !lowRange)}
            onClick={handleSaveRanges}
          >
            Set range
          </CancelButton>
        </ModalButtonWrapper>
      </ModalContenWrapper>
    </Modal>
  );
};

export default observer(SetRange);
