/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { observer } from 'mobx-react';
import React from 'react';
import { Navigate, RouteProps, Outlet } from 'react-router-dom';

import store from 'store';
//import { useAppNavigator } from 'utils';
import { ROUTES } from '_constants';

const PrivateRoute: React.FC<RouteProps> = (props: any) => {
  const { hasAuth } = store;
  return hasAuth ? <Outlet /> : <Navigate to={'/' + ROUTES.LOGIN} />;
};

export default observer(PrivateRoute);
