import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { ConnetionBlock, ModalContenWrapper, ConnectButtonWrapper, Link } from './ModalConnectToCGM.styles';
import { Modal, Button } from '@mui/material';
import { ConnectionImg, ConnetionTitle, ConnetionText, CloseIconButton, ConnetionImgWrapper } from '../CGMLink.styles';
import dexcom from 'assets/images/dexcom.png';
import freestyler from 'assets/images/freestylelibre.jpg';
import { Textfield, Password } from 'components';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import store from 'store';

interface Props {
  open: boolean;
  isConnectionToDexcom: boolean;
  setOpen: (val: boolean) => void;
}

const ModalConnectToCGM: React.FC<Props> = ({ open = false, isConnectionToDexcom = false, setOpen }) => {
  const [dexcomEmail, setDexcomEmail] = useState('');
  const [dexcomPassword, setDexcomPassword] = useState('');

  const { connectDescomShareApi, connectFreeStyleLibreApi, getCGMConnectionStatus } = store;

  const handleClose = () => {
    setOpen(false);
    setDexcomEmail('');
    setDexcomPassword('');
  };

  const dexcomConnect = async () => {
    await connectDescomShareApi(dexcomEmail, dexcomPassword);
    getCGMConnectionStatus();
    handleClose();
  };

  const freeStyleLibreConnect = async () => {
    await connectFreeStyleLibreApi(dexcomEmail, dexcomPassword);
    getCGMConnectionStatus();
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContenWrapper>
        <ConnetionBlock>
          <CloseIconButton onClick={handleClose}>
            <CloseRoundedIcon />
          </CloseIconButton>
          {isConnectionToDexcom ? (
            <>
              <ConnectionImg src={dexcom} />
              <ConnetionTitle>Dexcom</ConnetionTitle>
              <ConnetionText>
                Use your Dexcom api credentials to link your data. Credentials can be found within the Dexcom app on the
                wearer's mobile device.
                <br />
                For additional help view the Setup Guide{' '}
                <Link target="_blank" href="https://glowcose.com/setupv2">
                  here
                </Link>
                .
              </ConnetionText>
              <Textfield value={dexcomEmail} label={'Username'} setValue={setDexcomEmail} />
              <Password value={dexcomPassword} label={'Password'} setValue={setDexcomPassword} />
              <ConnectButtonWrapper>
                <Button
                  color="primary"
                  size="normal"
                  disabled={!dexcomEmail || !dexcomPassword}
                  fullWidth
                  variant="contained"
                  onClick={dexcomConnect}
                >
                  Submit
                </Button>
              </ConnectButtonWrapper>
            </>
          ) : (
            <>
              <ConnetionImgWrapper>
                <ConnectionImg src={freestyler} />
              </ConnetionImgWrapper>
              <ConnetionTitle>FreeStyle Libre</ConnetionTitle>
              <ConnetionText>
                As a prerequisite, you must install the LibreLinkUp mobile application and log in using your LibreView
                account credentials. Once finished, enter your credentials to link your CGM data.
                <br />
                For additional help, view the setup guide{' '}
                <Link target="_blank" href="https://glowcose.com/setupv2">
                  here
                </Link>
                .
              </ConnetionText>
              <Textfield value={dexcomEmail} label={'Email'} setValue={setDexcomEmail} />
              <Password value={dexcomPassword} label={'Password'} setValue={setDexcomPassword} />
              <ConnectButtonWrapper>
                <Button
                  color="primary"
                  size="normal"
                  disabled={!dexcomEmail || !dexcomPassword}
                  fullWidth
                  variant="contained"
                  onClick={freeStyleLibreConnect}
                >
                  Submit
                </Button>
              </ConnectButtonWrapper>
            </>
          )}
        </ConnetionBlock>
      </ModalContenWrapper>
    </Modal>
  );
};

export default observer(ModalConnectToCGM);
