/* eslint-disable @typescript-eslint/no-explicit-any */
import { request } from './base-api';

export const signIn = (params: any) => {
  return request('/api/v1/Authorization/sign-in', {
    method: 'POST',
    data: params,
  }).then((response: any) => response?.data);
};

export const createAccount = (params: any) => {
  return request('/api/v1/Authorization/sign-up', {
    method: 'POST',
    data: params,
  }).then((response: any) => response);
};

export const signOut = () => {
  return request('/api/v1/Authorization/sign-out', {
    method: 'POST',
    data: {},
  }).then((response: any) => response);
};

export const getCurrentUserReadings = () => {
  return request('/api/v1/Readings/current-user-readings', {
    method: 'GET',
  }).then((response: any) => response);
};

export const getCurrentUser = () => {
  return request('/api/v1/UserProfile/current-user', {
    method: 'GET',
  }).then((response: any) => response);
};

export const getDexcomeLoginUrl = () => {
  return request('/api/v1/Dexcom/authorization', {
    method: 'GET',
  }).then((response: any) => response);
};

export const setDexcomeCode = (params: any) => {
  return request('/api/v1/Dexcom/authorization/complete', {
    method: 'POST',
    data: params,
  }).then((response: any) => response);
};

export const saveRange = (params: any) => {
  return request('/api/v1/RangeSetup/save-range-setup', {
    method: 'POST',
    data: params,
  }).then((response: any) => response);
};

export const getCGMConnectionStatus = () => {
  return request('/api/v1/Readings/cgm-connection', {
    method: 'GET',
  }).then((response: any) => response);
};

export const getLiveDevices = () => {
  return request('/api/v1/Readings/live-devices', {
    method: 'GET',
  }).then((response: any) => response);
};
export const saveUserData = (params: any) => {
  return request('/api/v1/UserProfile/current-user', {
    method: 'POST',
    data: params,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then((response: any) => response);
};
export const savePassword = (params: any) => {
  return request('/api/v1/Authorization/password/change', {
    method: 'POST',
    data: params,
  }).then((response: any) => response);
};
export const connectDescomShareApi = (params: any) => {
  return request('/api/v1/Dexcom/authorization/share-api', {
    method: 'POST',
    data: params,
  }).then((response: any) => response);
};

export const connectFreeStyleLibreApi = (params: any) => {
  return request('/api/v1/FreeStyleLibre/authorization', {
    method: 'POST',
    data: params,
  }).then((response: any) => response);
};
export const sendResetEmail = (params: any) => {
  return request('/api/v1/Authorization/password/send-reset-email', {
    method: 'POST',
    data: params,
  }).then((response: any) => response);
};
export const resetPassword = (params: any) => {
  return request('/api/v1/Authorization/password/reset', {
    method: 'POST',
    data: params,
  }).then((response: any) => response);
};

export const setUserSettings = (params: any) => {
  return request('/api/v1/UserSettings', {
    method: 'POST',
    data: params,
  }).then((response: any) => response);
};
export const diconnectCGMDexcome = () => {
  return request('/api/v1/Readings/cgm-connection/disconnect', {
    method: 'POST',
    data: { clearCredentials: true },
  }).then((response: any) => response);
};

export default {
  signIn,
  createAccount,
  signOut,
  getCurrentUserReadings,
  getCurrentUser,
  getDexcomeLoginUrl,
  setDexcomeCode,
  saveRange,
  getCGMConnectionStatus,
  getLiveDevices,
  saveUserData,
  savePassword,
  connectDescomShareApi,
  sendResetEmail,
  resetPassword,
  setUserSettings,
  diconnectCGMDexcome,
  connectFreeStyleLibreApi,
};
