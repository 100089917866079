import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { WrapperConnetions, StyledTab, SearchIcon, LockOpenIcon } from './Account.styles';
import store from 'store';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import AccountContent from './AccountContent';
import Security from './Security';

const a11yProps = (index: number) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
};

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const Account: React.FC = () => {
  const {} = store;
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <WrapperConnetions>
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: { background: '#00A79D' },
        }}
        aria-label="secondary tabs example"
      >
        <StyledTab {...a11yProps(0)} icon={<SearchIcon />} iconPosition="start" label="Account" />
        <StyledTab {...a11yProps(1)} icon={<LockOpenIcon />} iconPosition="start" label="Security" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <AccountContent />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Security />
      </TabPanel>
    </WrapperConnetions>
  );
};

export default observer(Account);
