import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Content, Title } from '../Login.styles';
import { ROUTES } from '_constants';
import { Caption, ArrowBack, ArrowBackLink, TitleWrapper } from './ConfirmEmail.styles';
import arrow from 'assets/icons/arrow-back.svg';

const ConfirmEmail: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const confirmType = searchParams.get('comfirmtype');

  const gotoLogin = () => {
    navigate('/' + ROUTES.LOGIN);
  };

  return (
    <Content>
      <TitleWrapper>
        <Title>{confirmType === 'success' ? 'Welcome to glowcose' : ''}</Title>
      </TitleWrapper>
      {confirmType === 'success' ? (
        <Caption>
          You’ve successfully completed the registration. <br />
          Thank you! You can now use the glowcose app.
        </Caption>
      ) : (
        <Caption> It seems that the invitation link has expired or is no longer valid.</Caption>
      )}
      <ArrowBackLink onClick={gotoLogin}>
        <ArrowBack src={arrow} alt="Back to login" /> Back to login
      </ArrowBackLink>
    </Content>
  );
};

export default observer(ConfirmEmail);
