import { styled } from '@mui/material/styles';
import {
  TrendingUp as MuiTrendingUpIcon,
  Check as MuiCheckIcon,
  TrendingDown as MuiTrendingDown,
} from '@mui/icons-material';
import { Button } from '@mui/material';

export const ContainerHead = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '24px',
  margin: '0 24px',
  flexFlow: 'wrap',
  overflow: 'hidden',
});

export const CGIReadingWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'start',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flexGrow: '3',
  gap: '24px',
  flex: '6 auto',
  minWidth: '400px',
  width: '601px',
});

export const RangeSetupWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
  padding: '0 20px 20px',
  backgroundColor: '#fff',
  minWidth: '200px',
  width: '200px',
  flex: '1 200px',
  borderRadius: '6px',
  flexShrink: '4',
});

export const RangeTitle = styled('div')({
  margin: '20px 0 37px 0',
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: '500',
  color: 'rgba(58, 53, 65, 0.87)',
});

export const TrendingUpIcon = styled(MuiTrendingUpIcon)({
  fontSize: '26px',
  padding: '7px',
  color: '#9100B5',
  backgroundColor: 'rgba(145, 0, 181, 0.2)',
  borderRadius: '4px',
  marginRight: '12px',
});
export const CheckIcon = styled(MuiCheckIcon)({
  fontSize: '26px',
  padding: '7px',
  color: '#00A79D',
  backgroundColor: 'rgba(0, 167, 157, 0.2)',
  borderRadius: '4px',
  marginRight: '12px',
});
export const TrendingDownIcon = styled(MuiTrendingDown)({
  fontSize: '26px',
  padding: '7px',
  color: '#CA0000',
  backgroundColor: 'rgba(202, 0, 0, 0.2)',
  borderRadius: '4px',
  marginRight: '12px',
});

export const RangeLine = styled('div')({
  display: 'flex',
  marginBottom: '24px',
  backgroundColor: '#fff',
});
export const RangeLineTextWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});
export const RangeLineTitle = styled('div')({
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: '500',
});
export const RangeLineCaption = styled('div')({
  fontSize: '12px',
  lineHeight: '16px',
  color: 'rgba(58, 53, 65, 0.68)',
});

export const SetRangeButton = styled(Button)({
  height: '42px',
  backgroundColor: '#00A79D',
  color: '#FFFFFF',
  ':hover': {
    color: '#D8FFFF',
    backgroundColor: '#64A7A3',
  },
});

export const RangeHint = styled('div')({
  fontSize: '14px',
  lineHeight: '20px',
  color: 'rgba(58, 53, 65, 0.68)',
  margin: '30px 0 20px',
});

export const CGIReadingBlockWrapper = styled('div')({
  padding: '20px',
});

export const CGIReadingBlock = styled('div')({
  backgroundColor: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '6px',
  flex: '1 auto',
});

export const CGIReadingTitle = styled('div')({
  fontSize: '20px',
  lineHeight: '24px',
  fontWeight: '500',
});

export const CGIReadingValueWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
  margin: '20px 0',
  flexWrap: 'wrap',
  gap: '5px',
});

interface ICGIReadingValue {
  color?: string;
  fontsize: string;
  lineheight: string;
}

export const CGIReadingValue = styled('div')<ICGIReadingValue>(({ color = '#C99D03', fontsize, lineheight }) => ({
  fontSize: fontsize,
  lineHeight: lineheight,
  height: '100px',
  color: color,
  alignItems: 'end',
  display: 'flex',
}));

export const CGIReadingMeasure = styled('div')({
  fontSize: '30px',
  color: '#3A3541',
});

export const CGIReadingDate = styled('div')({
  fontSize: '16px',
  lineHeight: '20px',
  color: 'rgba(58, 53, 65, 0.68)',
});

export const CGIReadingTopBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexFlow: 'row wrap',
  gap: '24px',
  width: '100%',
});

export const ConnectionCodeWrapper = styled('div')({
  backgroundColor: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '6px',
  width: '100%',
});

export const ConnectionCode = styled('div')({
  padding: '20px 20px 35px',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
});

export const ConnectionCodeValueWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '300px',
  maxWidth: '380px',
  flexBasis: '0',
});

export const ConnectionCodeValue = styled('div')({
  fontSize: '40px',
  lineHeight: '42px',
});

export const ConnectionCodeCaption = styled('div')({
  fontSize: '12px',
  lineHeight: '20px',
  fontWeight: '400',
  color: 'rgba(58, 53, 65, 0.68)',
  margin: '20px 0 10px',
});

export const ConnectionCodeIcon = styled('img')({
  flexBasis: '154px',
});
