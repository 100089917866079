import { styled } from '@mui/material/styles';
import background from 'assets/images/login_background.jpg';

export const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${background})`,
  overflow: 'auto',
  height: '100%',
  minHeight: '100vh',
  backgroundPosition: 'center',
});

export const ContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  maxHeight: '100%',
  overflow: 'auto',
});
