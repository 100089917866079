import { styled } from '@mui/material/styles';
import { Link, Button, IconButton as MuiIconButton, ListItem as MuiListItem, List as MuiList } from '@mui/material';

interface IWrapper {
  width: string;
}

export const Wrapper = styled('div')<IWrapper>(({ width }) => ({
  minWidth: width,
  backgroundColor: '#28243D',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export const Logo = styled('img')({
  padding: '18px 24px 17px',
  maxWidth: '260px',
  height: '29px',
});
export const LogoWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: '#28243D',
});

export const MenuList = styled('div')({
  width: '260px',
  backgroundColor: '#28243D',
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
});

export const List = styled(MuiList)({
  width: '242px',
  color: '#fff',
  '& .MuiListItemIcon-root': {
    color: '#fff',
  },
  '&& .Mui-selected': {
    background: 'linear-gradient(#00948B, #00D5C9)',
    borderTopRightRadius: '50px 50px',
    borderBottomRightRadius: '50px 50px',
    '&, & .MuiListItemIcon-root': {
      color: '#fff',
    },
  },
  '& .MuiListItemButton-root:hover, && .Mui-selected:hover': {
    background: 'linear-gradient(#00D5C9, #07f0e3)',
    borderTopRightRadius: '50px 50px',
    borderBottomRightRadius: '50px 50px',
    '&, & .MuiListItemIcon-root': {
      color: '#fff',
    },
  },
});

export const ListItem = styled(MuiListItem)({
  marginBottom: '7px',
});

export const LogoutWrapper = styled('div')({
  display: 'flex',
  color: '#E7E3FC',
  margin: '28px',
});

export const LogoutText = styled(Link)({
  color: '#E7E3FC',
  ':hover': {
    color: '#cfcce2',
  },
  fontSize: '16px',
  lineHeight: '24px',
  marginLeft: '8px',
}) as typeof Link;

export const ModalContenWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  top: '50%',
  left: '50%',
  width: '400px',
  boxShadow: '24',
  borderRadius: '6px',
  padding: '24px',
  backgroundColor: '#FFFFFF',
});
export const ModalTitle = styled('div')({
  color: 'rgba(58, 53, 65, 0.87)',
  fontsize: '20px',
  lineHeight: '32px',
  fontWeight: '600',
});
export const ModalText = styled('div')({
  color: 'rgba(58, 53, 65, 0.68)',
  fontsize: '16px',
  lineHeight: '20px',
  margin: '10px 0 24px',
  textAlign: 'center',
});

export const ModalButtonWrapper = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-around',
});

export const CancelButton = styled(Button)({
  height: '42px',
  width: '180px',
  backgroundColor: '#00A79D',
  color: '#FFFFFF',
  ':hover': {
    color: '#D8FFFF',
    backgroundColor: '#64A7A3',
  },
}) as typeof Button;

export const LogoutButton = styled(Button)({
  height: '42px',
  width: '180px',
  border: '1px solid #00A79D',
  color: '#00A79D',
  ':hover': {
    color: '#64A7A3',
    backgroundColor: '#D8FFFF',
  },
}) as typeof Button;

export const ShowMenuButton = styled('div')({
  position: 'absolute',
  zIndex: '1100',
  top: '10px',
  left: '10px',
});
export const CloseIconButton = styled(MuiIconButton)({
  position: 'absolute',
  top: '0',
  right: '0',
});
