export enum ConnectionStatus {
  ALL_GOOD = 'allGood',
  NO_CURRENT_DATA = 'noCurrentData',
  NO_CONNECTION_SETUP = 'noConnectionSetup',
}

export enum ConnectionType {
  Dexcom = 'dexcom',
  FreeStyleLibre = 'freeStyleLibre',
  None = 'none',
}

export default ConnectionStatus;
