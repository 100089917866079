enum Routes {
  LOGIN = 'login',
  ROOT = '/',
  REGISTER = 'register',
  RESET_PASSWORD = 'reset-password',
  SIGN_UP = 'sign-up',
  FORGOT_PASSWORD = 'forgot-password',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  DASHBOARD = 'dashboard',
  ACCOUNT = 'account',
  CGM = 'cgm',
  HELP = 'help',
  SETDEXCOMCODE = 'setdexcomcode',
  NEEDDEXCOMELOGIN = 'need-dexcom-login',
  NOTICE_CHECK_EMAIL_AFTER_REGISTER = 'notice-email-check',
  NOTICE_CHECK_EMAIL_AFTER_FORGOT_PASS = 'check-email-after-forgot-pass',
  CONFIRMEMAIL = 'confirm-email',
}

export default Routes;
