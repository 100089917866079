// import Store from './Store';
// import ScormTabStore from './ScormTabStore';

// const store = Store.create();
// const scormTabStore = new ScormTabStore();
// const { DomainStore, UIStore } = store;

// export { DomainStore, UIStore, store, scormTabStore };
// export default store;

import Store from './Store';

const store = Store.create();

export default store;
