import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '_constants';
import {
  Wrapper,
  ContentWrapper,
  HeaderBlock,
  ContentBlock,
  Link,
  AvatarWrapper,
  FooterBlock,
  MainBlock,
  Email,
  TextFooter,
  ContainerHead,
} from '../Main/Main.styles';
import Menu from '../Main/Menu';
import { HelpBlock, Title, Text } from './Help.styles';
import { Avatar } from 'components';
import dayjs from 'dayjs';

const Main: React.FC = () => {
  const navigate = useNavigate();

  const gotoAccount = () => {
    navigate('/' + ROUTES.ACCOUNT);
  };

  return (
    <Wrapper>
      <Menu />
      <ContentWrapper>
        <MainBlock>
          <HeaderBlock>
            {/* <NotificationsNoneIcon /> */}
            <AvatarWrapper onClick={gotoAccount}>
              <Avatar />
            </AvatarWrapper>
          </HeaderBlock>
          <ContentBlock>
            <ContainerHead>
              <HelpBlock>
                <Title>Help</Title>
                <Text>
                  {'Need assistance? Click '}
                  <Link target="_blank" href="https://glowcose.com/setupv2">
                    this link
                  </Link>
                  {' to get started.'}
                </Text>
              </HelpBlock>
            </ContainerHead>
          </ContentBlock>
        </MainBlock>
        <FooterBlock>
          <TextFooter>
            © Glowcose {dayjs().year()}. All rights reserved. By using this website you agree to the &nbsp;
            <Link target="_blank" href="http://www.glowcose.com/terms">
              terms and conditions
            </Link>
            {' and '}
            <Link target="_blank" href="http://glowcose.com/privacy">
              privacy policy
            </Link>
            .
          </TextFooter>
          <Email target="_blank" href="https://glowcose.com/pages/contact-us">
            Support
          </Email>
        </FooterBlock>
      </ContentWrapper>
    </Wrapper>
  );
};

export default observer(Main);
