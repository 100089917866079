import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { WrapperConnetions, TextFieldsBlock, BottomButtonsWrapper, CancelButton } from './Security.styles';
import store from 'store';
import { Password } from 'components';
import { getPasswordValidateError } from 'utils';
import { Button } from '@mui/material';
import ConfirmNavigation from '../ConfirmNavigation';
import useBlocker from 'hooks/useBlocker';

const Security: React.FC = () => {
  const { savePassword } = store;
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState('');
  const [isFieldsChanged, setIsFieldsChanged] = useState(false);
  const [isFieldsValid, setIsFieldsValid] = useState(true);

  const [openModal, setOpenModal] = useState(false);

  const clearChange = useCallback(() => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    setCurrentPasswordError('');
    setNewPasswordError('');
    setConfirmNewPasswordError('');
  }, []);

  useEffect(() => {
    if (currentPassword || newPassword || confirmNewPassword) {
      setIsFieldsChanged(true);
    } else {
      setIsFieldsChanged(false);
    }
  }, [currentPassword, newPassword, confirmNewPassword]);

  useEffect(() => {
    if (
      currentPassword &&
      newPassword &&
      confirmNewPassword &&
      !(currentPasswordError || newPasswordError || confirmNewPasswordError)
    ) {
      setIsFieldsValid(true);
    } else {
      setIsFieldsValid(false);
    }
  }, [
    currentPasswordError,
    newPasswordError,
    confirmNewPasswordError,
    currentPassword,
    newPassword,
    confirmNewPassword,
  ]);

  useEffect(() => {
    const err = getPasswordValidateError(currentPassword);
    setCurrentPasswordError(err);
  }, [currentPassword]);

  useEffect(() => {
    let err = getPasswordValidateError(newPassword);
    if (!err && currentPassword && currentPassword === newPassword) {
      err = 'The current password must not be equal to the new one';
    }
    setNewPasswordError(err);
  }, [newPassword, currentPassword]);

  useEffect(() => {
    let err = getPasswordValidateError(confirmNewPassword);
    if (!err && confirmNewPassword && confirmNewPassword !== newPassword) {
      err = 'Password mismatch';
    }
    setConfirmNewPasswordError(err);
  }, [confirmNewPassword, newPassword]);

  const saveData = async () => {
    if (isFieldsChanged && isFieldsValid) {
      await savePassword(currentPassword, newPassword, confirmNewPassword);
      await clearChange();
    }
  };

  const { blocker, setIsChanged } = useBlocker(isFieldsChanged && isFieldsValid, setOpenModal);
  useEffect(() => {
    setIsChanged(isFieldsChanged && isFieldsValid);
  }, [setIsChanged, isFieldsChanged, isFieldsValid]);

  const handleSave = async () => {
    await setOpenModal(false);
    await saveData();
    await blocker.proceed?.();
  };
  const handleDiscard = () => {
    setOpenModal(false);
    blocker.proceed?.();
  };

  return (
    <WrapperConnetions>
      <TextFieldsBlock>
        <Password
          key={'currentPassword'}
          isNeedValid={true}
          label={'Current Password'}
          value={currentPassword}
          setValue={setCurrentPassword}
          error={currentPasswordError}
        />
      </TextFieldsBlock>
      <TextFieldsBlock>
        <Password
          key={'newPassword'}
          isNeedValid={true}
          label={'New Password'}
          value={newPassword}
          setValue={setNewPassword}
          error={newPasswordError}
        />
      </TextFieldsBlock>
      <TextFieldsBlock>
        <Password
          key={'confirmNewPassword'}
          isNeedValid={true}
          label={'Confirm New Password'}
          value={confirmNewPassword}
          setValue={setConfirmNewPassword}
          error={confirmNewPasswordError}
        />
      </TextFieldsBlock>
      <BottomButtonsWrapper>
        <Button
          color="primary"
          size="smaller"
          disabled={!isFieldsChanged || !isFieldsValid}
          variant="contained"
          onClick={saveData}
        >
          Save Changes
        </Button>
        <CancelButton
          color="secondary"
          size="smaller"
          disabled={!isFieldsChanged}
          variant="outlined"
          onClick={clearChange}
        >
          Cancel
        </CancelButton>
      </BottomButtonsWrapper>
      {blocker ? <ConfirmNavigation open={openModal} handleDiscard={handleDiscard} handleSave={handleSave} /> : null}
    </WrapperConnetions>
  );
};

export default observer(Security);
