import React from 'react';
import { observer } from 'mobx-react';
import { ModalContenWrapperCGMconnection } from '../ModalLiveDevice/ModalLiveDevice.styles';
import Modal from '@mui/material/Modal';
import CGMLink from 'views/Main/CGMLink';

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
}

const ModalCGMConnection: React.FC<Props> = ({ open = false, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContenWrapperCGMconnection>
        <CGMLink isFirstLogin={true} isModal={true} handleSkip={handleClose} />
      </ModalContenWrapperCGMconnection>
    </Modal>
  );
};

export default observer(ModalCGMConnection);
