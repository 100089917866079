import React from 'react';
import { observer } from 'mobx-react';
import { Backdrop, CircularProgress } from '@mui/material';

import store from 'store';

const FullPageLoader = () => {
  const { showLoader } = store;
  return (
    <div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={showLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default observer(FullPageLoader);
