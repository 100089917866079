import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const WrapperConnetions = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '0',
  padding: '0',
  backgroundColor: '#FFFFFF',
  borderRadius: '6px',
  position: 'relative',
});

export const AvatarBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',
  flexWrap: 'wrap',
});

export const Avatar = styled('img')({
  width: '120px',
  maxWidth: '120px',
});

export const ButtonsBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '16px',
});

export const ButtonsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '16px',
});

export const UploadButton = styled(Button)({
  height: '38px',
  lineHeight: '24px',
  backgroundColor: '#00A79D',
  color: '#FFFFFF',
  ':hover': {
    color: '#D8FFFF',
    backgroundColor: '#64A7A3',
  },
});

export const ResetButton = styled(Button)({
  height: '38px',
  lineHeight: '24px',
  backgroundColor: '#FFF',
  color: '#FF4C51',
  borderColor: '#FF4C51',
  ':hover': {
    color: '#d32f2f',
    borderColor: '#d32f2f',
    backgroundColor: '#f1f1f1',
  },
});

export const AvatarUploadCaption = styled('div')({
  fontSize: '12px',
  lineHeight: '24px',
  color: 'rgba(58, 53, 65, 0.68)',
});

export const TextFieldsBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 0 20px',
});

export const TextFieldsLine = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
});

export const LeftBlockWithPadding = styled('div')({
  paddingLeft: '10px',
  width: '50%',
});

export const RightBlockWithPadding = styled('div')({
  width: '50%',
  paddingRight: '10px',
});

export const DatepickerTextField = styled('div')({
  width: '50%',
  margin: '8px 0 0',
  paddingLeft: '10px',
});

export const BottomButtonsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '24px 0',
});

export const CancelButton = styled(Button)({
  marginLeft: '16px',
});
