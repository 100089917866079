import { styled } from '@mui/material/styles';
import {
  TrendingUp as MuiTrendingUpIcon,
  TrendingDown as MuiTrendingDown,
  Check as MuiCheckIcon,
} from '@mui/icons-material';
import { Button, TextField as MuiTextField, IconButton as MuiIconButton } from '@mui/material';

export const RangeSetupWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  width: '100%',
  marginBottom: '15px',
});

export const RangeTitle = styled('div')({
  margin: '20px 0 37px 0',
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: '500',
  color: 'rgba(58, 53, 65, 0.87)',
});

export const TrendingUpIcon = styled(MuiTrendingUpIcon)({
  fontSize: '26px',
  color: '#9100B5',
  backgroundColor: 'rgba(145, 0, 181, 0.2)',
  borderRadius: '4px',
  marginRight: '12px',
  padding: '7px',
});
export const CheckIcon = styled(MuiCheckIcon)({
  fontSize: '26px',
  color: '#00A79D',
  backgroundColor: 'rgba(0, 167, 157, 0.2)',
  borderRadius: '4px',
  marginRight: '12px',
  padding: '7px',
});
export const TrendingDownIcon = styled(MuiTrendingDown)({
  fontSize: '26px',
  color: '#CA0000',
  backgroundColor: 'rgba(202, 0, 0, 0.2)',
  borderRadius: '4px',
  marginRight: '12px',
  padding: '7px',
});

export const RangeLine = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '24px',
  backgroundColor: '#fff',
});

export const RangeInputWrapper = styled('div')({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
});
export const RangeLineError = styled('div')({
  display: 'flex',
  fontSize: '12px',
  color: '#d32f2f',
});

export const RangeLineTitle = styled('div')({
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: '500',
});
export const RadioGroupWrapper = styled('div')({
  width: '100%',
  padding: '15px 0',
});

export const ModalContenWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  top: '50%',
  left: '50%',
  width: '260px',
  boxShadow: '24',
  borderRadius: '6px',
  padding: '24px',
  backgroundColor: '#FFFFFF',
});
export const ModalTitle = styled('div')({
  color: 'rgba(58, 53, 65, 0.87)',
  fontsize: '20px',
  lineHeight: '32px',
  fontWeight: '600',
});
export const ModalText = styled('div')({
  color: 'rgba(58, 53, 65, 0.68)',
  fontsize: '14px',
  lineHeight: '20px',
  marginTop: '10px',
});

export const ModalButtonWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
  alignItems: 'center',
});

export const CancelButton = styled(Button)({
  height: '42px',
  width: '100%',
  backgroundColor: '#00A79D',
  color: '#FFFFFF',
  ':hover': {
    color: '#D8FFFF',
    backgroundColor: '#64A7A3',
  },
}) as typeof Button;

export const LogoutButton = styled(Button)({
  height: '42px',
  width: '100%',
  border: '1px solid #00A79D',
  color: '#00A79D',
  ':hover': {
    color: '#64A7A3',
    backgroundColor: '#D8FFFF',
  },
}) as typeof Button;

export const TextField = styled(MuiTextField)({
  maxWidth: '120px',
});

export const IconButton = styled(MuiIconButton)({
  position: 'absolute',
  top: '0',
  right: '0',
});

export const SwitchText = styled('div')({
  fontSize: '12px',
  lineHeight: '16px',
  color: 'rgba(58, 53, 65, 0.68)',
});
