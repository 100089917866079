import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '_constants';
import {
  Content,
  Title,
  Caption,
  SaveButton,
  CreateAccounText,
  CreateAccounWrapper,
  CreateAccountLink,
  Line,
  TermsText,
  TermsWrapper,
  TermsLink,
  TitleWrapper,
} from '../Login.styles';
import store from 'store';
import { isPasswordValid, getPasswordValidateError } from 'utils';
import { Checkbox } from '@mui/material';
import { Textfield, Email, Password, Phone } from 'components';
import {
  TermsAndConditionsWrapper,
  TermsAndConditionsText,
  TermsAndConditionsLink,
  CountrySelector,
} from './RegisterForm.styles';

const RegisterForm: React.FC = () => {
  const [agreementWithTerms, setAgreementWithTerms] = useState(false);
  const [passwordError, setPasswordError] = useState('');

  const {
    country,
    setCountry,
    createAccount,
    clearAuth,
    isEmailValid,
    setFirstname,
    setLastname,
    firstname,
    lastname,
    needShowNoticeCheckEmail,
    setNeedShowNoticeCheckEmail,
    setPassword,
    password,
  } = store;
  const navigate = useNavigate();

  useEffect(() => {
    if (needShowNoticeCheckEmail) {
      navigate('/' + ROUTES.LOGIN + '/' + ROUTES.NOTICE_CHECK_EMAIL_AFTER_REGISTER);

      setNeedShowNoticeCheckEmail(false);
    }
  }, [needShowNoticeCheckEmail, navigate, setNeedShowNoticeCheckEmail]);

  useEffect(() => {
    const err = getPasswordValidateError(password);
    setPasswordError(err);
  }, [password]);

  const gotoLogin = () => {
    clearAuth();
    navigate('/' + ROUTES.LOGIN);
  };

  return (
    <Content>
      <TitleWrapper>
        <Title>{'Welcome to glowcose!'}</Title>
        <Caption>Sign up to setup your CGM readings in color.</Caption>
      </TitleWrapper>

      <Textfield
        value={firstname}
        label={'First name'}
        //validateError={getFirstnameError}
        setValue={setFirstname}
      />
      <Textfield
        value={lastname}
        label={'Last name'}
        //validateError={getLastnameError}
        setValue={setLastname}
      />
      <Email isNeedValid={true} />
      <Password isNeedValid={true} value={password} setValue={setPassword} error={passwordError} />
      <Phone />
      <CountrySelector value={country} onChange={setCountry} priorityOptions={['US']} valueType="short" />
      <TermsAndConditionsWrapper>
        <Checkbox value={agreementWithTerms} onClick={() => setAgreementWithTerms(!agreementWithTerms)} size="small" />
        <TermsAndConditionsText>{'I Agree to '}</TermsAndConditionsText>
        <TermsAndConditionsLink underline="none" target="_blank" href="http://www.glowcose.com/terms">
          terms and conditions
        </TermsAndConditionsLink>
      </TermsAndConditionsWrapper>
      <SaveButton
        disabled={!(isEmailValid && isPasswordValid(password) && country && agreementWithTerms)}
        fullWidth
        variant="contained"
        onClick={createAccount}
      >
        Sign up
      </SaveButton>
      <CreateAccounWrapper>
        <CreateAccounText>Already have an account?</CreateAccounText>
        <CreateAccountLink component={'button'} underline="none" onClick={gotoLogin}>
          Sign in instead
        </CreateAccountLink>
      </CreateAccounWrapper>
      <Line></Line>
      <TermsWrapper>
        <TermsText>By using this website you agree to the&nbsp;</TermsText>
        <TermsLink underline="none" target="_blank" href="http://www.glowcose.com/terms">
          terms and conditions
        </TermsLink>
        {' and '}
        <TermsLink underline="none" target="_blank" href="http://glowcose.com/privacy">
          privacy policy
        </TermsLink>
        .
      </TermsWrapper>
    </Content>
  );
};

export default observer(RegisterForm);
