import React from 'react';
import { observer } from 'mobx-react';
import {
  ModalWrapper,
  ModalContenWrapper,
  ModalButtonWrapper,
  CancelButton,
  ModalTitle,
  ModalTextTop,
  ModalTextBottom,
  CloseIconButton,
  TextLink,
} from '../ModalLiveDevice/ModalLiveDevice.styles';
import Modal from '@mui/material/Modal';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
}

const ModalSupportEmail: React.FC<Props> = ({ open = false, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalWrapper>
        <ModalContenWrapper>
          <CloseIconButton onClick={handleClose}>
            <CloseRoundedIcon />
          </CloseIconButton>
          <ModalTitle>Check your CGM connection</ModalTitle>
          <ModalTextTop>Unable to fetch CGM data in the last few minutes.</ModalTextTop>
          <ModalTextTop>Please make sure your CGM is connected to the internet and actively sending data.</ModalTextTop>
          <ModalTextBottom>
            If the issue persists, please contact{' '}
            <TextLink target="_blank" href="http://www.glowcose.com/contact">
              technical support
            </TextLink>
            .
          </ModalTextBottom>
          <ModalButtonWrapper>
            <CancelButton onClick={handleClose}>Ok</CancelButton>
          </ModalButtonWrapper>
        </ModalContenWrapper>
      </ModalWrapper>
    </Modal>
  );
};

export default observer(ModalSupportEmail);
