import background from 'assets/images/login_background.jpg';
import { Link, Button, TextField } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';

export const LogoWrapper = muiStyled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  padding: '48px 0 32px',
});

export const Wrapper = muiStyled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${background})`,
  overflow: 'auto',
  height: '100%',
  minHeight: '100vh',
  backgroundPosition: 'center',
});

export const ContentWrapper = muiStyled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '450px',
  backgroundColor: '#fff',
  borderRadius: '6px',
});

export const Logo = muiStyled('img')({
  width: '100%',
  maxWidth: '300px',
});

export const Content = muiStyled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  padding: '0 28px 36px',
});

export const TitleWrapper = muiStyled('div')({
  width: '100%',
});

export const Title = muiStyled('div')({
  textAlign: 'center',
  fontSize: '24px',
  lineHeigth: '32px',
  fontWeight: '700',
  color: 'rgba(58, 53, 65, 0.87)',
});

export const Caption = muiStyled('div')({
  fontSize: '14px',
  lineHeigth: '20px',
  fontWeight: '400',
  color: 'rgba(58, 53, 65, 0.68)',
  margin: '5px 0 15px',
  textAlign: 'center',
});

export const Input = muiStyled(TextField)({
  marginTop: '20px',
});

export const LinkWrapper = muiStyled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%',
  margin: '5px 0 20px',
});

export const CheckboxWrapper = muiStyled('div')({
  paddingLeft: '12px',
});

export const SaveButton = muiStyled(Button)({
  height: '42px',
  backgroundColor: '#00A79D',
  color: '#FFFFFF',
  ':hover': {
    color: '#D8FFFF',
    backgroundColor: '#64A7A3',
  },
}) as typeof Button;

export const ForgotPassLink = muiStyled(Link)({
  color: '#00A79D',
  ':hover': {
    color: '#64A7A3',
  },
}) as typeof Link;

export const CreateAccounText = muiStyled('div')({
  fontSize: '16px',
  lineHeight: '24px',
  color: 'rgba(58, 53, 65, 0.87)',
});

export const CreateAccounWrapper = muiStyled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  marginTop: '28px',
});

export const CreateAccountLink = muiStyled(Link)({
  color: '#00A79D',
  ':hover': {
    color: '#64A7A3',
  },
  fontSize: '16px',
  lineHeight: '24px',
  paddingLeft: '5px',
}) as typeof Link;

export const Line = muiStyled('div')({
  width: '100%',
  margin: '20px 0 46px',
  border: '1px solid rgba(58, 53, 65, 0.1)',
});

export const TermsText = muiStyled('div')({
  color: 'rgba(58, 53, 65, 0.87)',
  display: 'inline-block',
});

export const TermsWrapper = muiStyled('div')({
  width: '100%',
  fontSize: '12px',
});

export const TermsLink = muiStyled(Link)({
  color: '#00A79D',
  ':hover': {
    color: '#64A7A3',
  },
  fontSize: '12px',
}) as typeof Link;
