import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    dashed: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    normal: true;
    smaller: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#00A79D',
      contrastText: '#fff',
    },
    secondary: {
      main: '#8A8D93',
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: '#fff',
          fontSize: '12px',
          lineHeight: '16px',
          backgroundColor: '#00A79D',
          padding: '15px',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { size: 'normal' },
          style: {
            padding: '9px 18px',
          },
        },
        {
          props: { size: 'smaller' },
          style: {
            padding: '6px 18px',
          },
        },
      ],
    },
  },
});

// const theme2 = createTheme({
//   components: {
//     MuiButton: {
//       variants: [
//         {
//           props: { variant: 'dashed' },
//           style: {
//             textTransform: 'none',
//             border: `2px dashed #00A79D`,
//           },
//         },
//         {
//           props: { variant: 'dashed', color: 'secondary' },
//           style: {
//             border: `4px dashed #00A79D`,
//           },
//         },
//       ],
//     },
//   },
// });

type Props = { children: React.ReactNode };

const ThemeProvider: React.FC<Props> = ({ children }) => {
  return <MuiThemeProvider theme={theme}>{children} </MuiThemeProvider>;
};

export default ThemeProvider;
