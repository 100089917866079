import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import store from 'store';

export interface ISnackbarMessage {
  message: string;
  key: number;
}

export interface State {
  open: boolean;
  snackPack: readonly ISnackbarMessage[];
  messageInfo?: ISnackbarMessage;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarMessage = () => {
  const { errMessage, noticedMessage, setNoticedMessage, setErrMessage } = store;
  const [snackPack, setSnackPack] = useState<readonly ISnackbarMessage[]>([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState<ISnackbarMessage | undefined>(undefined);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added

      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleClick = (message: string) => {
    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  };

  useEffect(() => {
    if (errMessage || noticedMessage) {
      setIsSuccessMessage(!!noticedMessage);
      handleClick(errMessage || noticedMessage);
      if (noticedMessage) {
        setNoticedMessage('');
      }
      if (errMessage) {
        setErrMessage('');
      }
    }
  }, [errMessage, noticedMessage, setNoticedMessage, setErrMessage]);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };
  return (
    <Snackbar
      key={messageInfo ? messageInfo.key : undefined}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
    >
      <Alert onClose={handleClose} onClick={handleClose} severity={isSuccessMessage ? 'success' : 'error'}>
        {messageInfo ? messageInfo.message : undefined}
      </Alert>
    </Snackbar>
  );
};

export default observer(SnackbarMessage);
